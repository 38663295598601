$(document).ready(function () {

    const menuElement = document.getElementById('slide-menu');
    const allEvents = [
        'sm.back',
        'sm.open',
        'sm.close'
    ];

    allEvents.forEach(eventName => {
        menuElement.addEventListener(eventName, event);
    });


    const menu = new SlideMenu(menuElement, {
        position: 'left',
        submenuLinkAfter: ' <i class="fas fa-chevron-right float-right mt-1"></i>',
        backLinkBefore: '<i class="fas fa-chevron-left mr-2"></i> ',
    });

    menuElement.addEventListener('sm.open', function () {
        $('.menu_overlay').removeClass('d-none');
    })
    menuElement.addEventListener('sm.close', function () {
        $('.menu_overlay').addClass('d-none');
    })
    $('.menu_overlay').on('click', function () {
        menu.close();
    })

    $(document).on('mousemove', '.hover_image_gallery', function (evt) {
        let target = $(evt.currentTarget).closest('.owl-carousel');
        let mouse_position = evt.pageX - $(target).offset().left;
        let image_count = $(evt.currentTarget).closest('.owl-stage').children().length;
        let width = $(this).width();
        let areas = [];
        let new_position = 0;
        for (let i = 0; i < image_count; i++) {
            areas.push({'start': new_position, 'end': (width / image_count) + new_position});
            new_position += width / image_count;
        }
        obj = areas.find((o) => {
            if (mouse_position > o.start && mouse_position < o.end) {
                $(this).closest('.owl-carousel').trigger('to.owl.carousel', areas.indexOf(o));
            }
        })

        //
    })

    $(document).on('mouseleave', '.hover_image_gallery', function (evt) {
        let target = $(evt.currentTarget).closest('.owl-carousel');
        target.trigger('to.owl.carousel', 0);
    })

});
